<template>
  <a-dropdown placement="bottomRight">
    <div class="header" @click="handleToCenter()" style="background:#892E65!important;width:45px;text-align:center">
      <a-icon type="profile" style="color: #fff;font-size:24px"/>
    </div>
  </a-dropdown>
</template>
    
<script>
export default {
  name: 'AvatarDropdown',
  props: {
    currentUser: {
      type: Object,
      default: () => null,
    },
    menu: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleToCenter() {
     window.open('http://doc.52jiewang.com/abacus','_blank')
    },
    handleToSettings() {
      this.$router.push({ path: '/account/settings' })
    },
  },
}
</script>
<style lang="less" scoped>
.ant-pro-drop-down {
  :deep(.action) {
    margin-right: 8px;
  }
  :deep(.ant-dropdown-menu-item) {
    min-width: 160px;
  }
}
</style>
    