// @ts-ignore
/* eslint-disable */
import request from '@/utils/request';

/** 获取我的常用功能列表 POST /fds/home/getFunctionList */
export async function getFunctionListUsingPOST1(options) {
  return request({
    url: '/fds/home/getFunctionList',
    method: 'POST',
    ...(options || {}),
  });
}

/** 获取更新日志列表 POST /fds/home/getSysUpdateLogList */
export async function getSysUpdateLogListUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  options,
) {
  return request({
    url: '/fds/home/getSysUpdateLogList', 
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 修改我的常用功能列表 POST /fds/home/updateFunctionList */
export async function getFunctionListUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  options,
) {
  return request({
    url: '/fds/home/updateFunctionList', 
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
