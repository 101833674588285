<template>
  <a-dropdown placement="bottomRight">
    <div class="header" style="background: #dd5a43 !important; width: 45px; text-align: center">
      <a-icon type="phone" style="color: #fff; font-size: 24px" />
    </div>
    <template v-slot:overlay>
      <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
        <a-menu-item v-if="menu" key="center">
          <a-icon type="phone" />
          业务联系电话：
          <div style="text-align: center">李先生 18860155588</div>
        </a-menu-item>
        <a-menu-item v-if="menu" key="center">
          <a-icon type="phone" />
          投诉电话：
          <div style="text-align: center">陈先生 18960822363</div>
        </a-menu-item>
        <!-- <a-menu-item v-if="menu" key="settings" @click="handleToSettings">
            <a-icon type="setting" />
            {{ $t('menu.account.settings') }}
          </a-menu-item> -->
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
export default {
  name: 'AvatarDropdown',
  props: {
    currentUser: {
      type: Object,
      default: () => null,
    },
    menu: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleToCenter() {
      this.$router.push({ path: '/my/info' })
    },
    handleToSettings() {
      this.$router.push({ path: '/account/settings' })
    },
  },
}
</script>

<style lang="less" scoped>
.header {
  // background-color: #a34335;
  color: #fff;
}
.header:hover {
  // background-color: #a34335;
}
.ant-pro-drop-down {
  :deep(.action) {
    margin-right: 8px;
  }
  :deep(.ant-dropdown-menu-item) {
    min-width: 160px;
  }
}
</style>
