// @ts-ignore
/* eslint-disable */
import request from '@/utils/request'


/** 全部已读 POST /fds/my/allReadMyMsg */
export async function allReadMyMsgUsingPOST(options) {
  return request('/fds/my/allReadMyMsg', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 导出我的消息 POST /fds/my/exportMymsg */
export async function exportMymsgUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  options,
) {
  return request('/fds/my/exportMymsg', {
    method: 'POST',
    responseType: 'blob',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 清空消息 POST /fds/my/deleteAllMyMsg */
export async function deleteAllMyMsgUsingPOST(options) {
  return request('/fds/my/deleteAllMyMsg', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 删除我的消息 POST /fds/my/deleteMyMsg */
export async function deleteMyMsgUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  options,
) {
  return request('/fds/my/deleteMyMsg', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
/** 消息详情 POST /fds/my/getMyMsg */
export async function getMyMsgUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  options,
) {
  return request('/fds/my/getMyMsg', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取我的信息 GET /fds/my/myInfo */
export async function myInfoUsingGET(options) {
  return request('/fds/my/myInfo', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取我的消息 POST /fds/my/myMsgList */
export async function myMsgListUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  options,
) {
  return request('/fds/my/myMsgList', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 修改密码 POST /fds/my/updatePassword */
export async function updatePasswordUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  options
) {
  return request({
    url: '/fds/my/updatePassword',
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  })
}
