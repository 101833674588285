export function computer(date) {
    const now = new Date()
    const future = new Date(date) //距离的时间
    const index = (future - now) / 1000 / (60 * 60 * 24)
    const day = parseInt(index) //天
    const h = parseInt((index - day) * 24) //时
    const m = parseInt((index - day - h / 24) * 24 * 60) //分
    const s = parseInt((index - day - h / 24 - m / 60 / 24) * 24 * 60 * 60) //秒
    const str = day + '天' + h + '小时' + m + '分' + s + '秒'
    return str
}