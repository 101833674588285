<template>
  <page-header-wrapper :title="false">
    <router-view />
  </page-header-wrapper>
</template>

<script>
export default {
  name: 'PageView',
}
</script>
