<template>
  <a-dropdown placement="bottomRight">
    <div class="header" style="background:#f79263!important;width:45px;text-align:center">
      <a-icon type="search" style="color: #fff;font-size:24px"/>
    </div>
    <template v-slot:overlay>
      <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
        <a-menu-item v-if="menu" key="center" @click="handleToCenter('/cashier/orderSearch')">
          <a-icon type="search" />
          订单速查
        </a-menu-item>
        <a-menu-item v-if="menu" key="center" @click="handleToCenter('/goods/snSearch')">
          <a-icon type="gift" />
          商品串码速查
        </a-menu-item>
        <a-menu-item v-if="menu" key="center" @click="handleToCenter('/stock/storeShop/1')">
          <a-icon type="gift" />
          商品库存速查
        </a-menu-item>
        <a-menu-item v-if="menu" key="center" @click="handleToCenter('/member/orderSearch')">
          <a-icon type="user" />
          会员速查
        </a-menu-item>
        <a-menu-item v-if="menu" key="center" @click="handleToCenter('/cashier/mobileSearch')">
          <a-icon type="global" />
          运营商业务速查
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>
<script>
export default {
  name: 'AvatarDropdown',
  props: {
    currentUser: {
      type: Object,
      default: () => null,
    },
    menu: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleToCenter(item) {
      this.$router.push({ path:item})
    },
    handleToSettings() {
      this.$router.push({ path: '/account/settings' })
    },
  },
}
</script>
    
<style lang="less" scoped>
.ant-pro-drop-down {
  :deep(.action) {
    margin-right: 8px;
  }
  :deep(.ant-dropdown-menu-item) {
    min-width: 160px;
  }
}
</style>
    