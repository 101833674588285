<template>
  <div
    :class="wrpCls"
    class="headerRight"
    style="display: flex; justify-content: center; padding-left: 0; color: #ffffff"
  >
    <span v-if="!this.isMobile" style="size: 18px; margin-right: 20px"
      >{{ timeArry.timeText }} {{ timeArry.week }}</span
    >
    <!-- <Guanwang :menu="showMenu" :class="prefixCls" /> -->
    <operation :menu="showMenu" :class="prefixCls" />
    <Contact :menu="showMenu" :class="prefixCls" />
    <Search :menu="showMenu" :class="prefixCls" />
    <Notice :menu="showMenu" :class="prefixCls" />
    <!-- <tags-view /> -->
    <avatar-dropdown :menu="showMenu" :current-user="currentUser" :class="prefixCls" />
    <!-- <select-lang :class="prefixCls" /> -->
  </div>
</template>

<script>
import AvatarDropdown from './AvatarDropdown'
import Contact from './contact'
import Search from './search'
import Guanwang from './guanwang'
import Notice from './Notice'
import SelectLang from '@/components/SelectLang'
import { formatDate } from '@/utils/formatDate'
import Operation from './operation.vue'

export default {
  name: 'RightContent',
  components: {
    AvatarDropdown,
    Contact,
    Guanwang,
    Notice,
    Search,
    SelectLang,
    Operation,
  },
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-global-header-index-action',
    },
    isMobile: {
      type: Boolean,
      default: () => false,
    },
    topMenu: {
      type: Boolean,
      required: true,
    },
    theme: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showMenu: true,
      timer: null,
      timeArry: '',
      currentUser: {},
    }
  },
  created() {
    this.timeArry = formatDate(new Date())
    this.timeStart()
  },

  computed: {
    wrpCls() {
      return {
        'ant-pro-global-header-index-right': true,
        [`ant-pro-global-header-index-${this.isMobile || !this.topMenu ? 'light' : this.theme}`]: true,
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.currentUser = { ...this.$store.state.user }
    }, 1500)
  },
  methods: {
    timeStart() {
      // 设置定时器
      this.timer = setInterval(() => {
        this.timeArry = formatDate(new Date())
      }, 1000)
    },
  },
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 570px) {
  /* 在此处编写对应的样式 */
  .headerRight {
    background: #39799e;
  }
}
</style>
